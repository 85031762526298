<template>
  <div>
    <el-form :inline="true" :model="queryForm" size="small">
      <el-form-item label="选择房间">
        <el-cascader v-model="houseArr_queryForm" :options="houseCascaderData" :props="cascaderProps" clearable
          @change="handleCascaderChange_queryForm" class="el-input_inner--rewrite"></el-cascader>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleAdd" type="primary" size="small">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" style="width: 100%;" max-height="500px">
      <el-table-column label="#" type="index"></el-table-column>
      <el-table-column label="床位名称" v-slot="{ row }">
        {{ `${row.room.floor.building.area.name} / ${row.room.floor.building.name} / ${row.room.floor.name} / ${row.room.name} / ${row.name}` }}
      </el-table-column>
      <el-table-column label="是否启用" v-slot="{ row }">
        <el-tag v-if="row.status === 1" type="success" size="small">启用</el-tag>
        <el-tag v-else type="info" size="small">禁用</el-tag>
      </el-table-column>
      <el-table-column label="操作" v-slot="{ row }">
        <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" :page-size="pageSize" @current-change="getBedData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="closeDialog"
      width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="床位名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="选择房间" prop="houseArr">
          <el-cascader v-model="form.houseArr" :options="houseCascaderData" :props="cascaderProps" clearable
            class="el-input_inner--rewrite"></el-cascader>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-switch v-model="form.status" :active-value="1" :inactive-value="0" active-color="#13ce66"
            inactive-color="#ff4949" active-text="启用" inactive-text="禁用">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button @click="handleSubmit" :loading="formSubmitting" type="primary">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    saveBedAPI,
    getBedDataAPI,
    getHouseCascaderDataAPI,
  } from '@/api/base-data.js'

  export default {
    name: 'Bed',
    data() {
      return {
        houseCascaderData: [],
        cascaderProps: {
          label: 'name',
          value: 'id'
        },

        // 搜索
        houseArr_queryForm: [],
        queryForm: {
          page: 1,
          home_room_id: '',
        },

        // 表格相关
        tableData: [],
        total: 0,
        pageSize: 0,
        tableLoading: false,
        curRowData: null,

        // 表单相关
        showDialog: false,
        dialogTitle: '',
        formSubmitting: false,
        form: {
          name: '',
          houseArr: [],
          status: 1
        },
        rules: {
          name: [{
            required: true,
            message: '床位名称不能为空',
            trigger: 'blur'
          }],
          houseArr: [{
            required: true,
            message: '房间不能为空',
            trigger: 'change'
          }],
        }
      }
    },
    created() {
      this.getBedData()
      this.getHouseCascaderData()
    },
    methods: {
      // 新增按钮
      handleAdd() {
        this.showDialog = true
        this.dialogTitle = '新增'
      },
      // 查询按钮
      handleSearch() {
        this.queryForm.page = 1
        this.getBedData()
      },
      // 搜索表单的级联改变 --设置 queryForm.home_room_id
      handleCascaderChange_queryForm(e) {
        if (e.length > 3) this.queryForm.home_room_id = e[e.length - 1]
        else this.queryForm.home_room_id = ''
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      // 编辑按钮
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowData = JSON.parse(JSON.stringify(row))
        // 设置 form
        for (let key in this.form) {
          this.form[key] = this.curRowData[key]
        }
        // 回显房间
        this.form.houseArr = [
          this.curRowData.home_area_id,
          this.curRowData.home_building_id,
          this.curRowData.home_floor_id,
          this.curRowData.home_room_id
        ]
      },
      // 提交按钮
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return
          if (this.form.houseArr.length < 4) return this.$message.error('未选择到房间，请检查')

          this.formSubmitting = true
          const data = {
            name: this.form.name,
            home_area_id: this.form.houseArr[0],
            home_building_id: this.form.houseArr[1],
            home_floor_id: this.form.houseArr[2],
            home_room_id: this.form.houseArr[3],
            status: this.form.status
          }
          if (this.dialogTitle === '编辑') data.id = this.curRowData.id
          // 提交
          saveBedAPI(data).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getBedData()
          }).finally(() => this.formSubmitting = false)
        })
      },
      // 获取床位数据
      getBedData() {
        this.tableLoading = true

        getBedDataAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.pageSize = res.per_page
          this.tableLoading = false
        })
      },
      // 获取级联数据（获取到房间）
      getHouseCascaderData() {
        getHouseCascaderDataAPI({
          level: 3
        }).then(res => {
          this.houseCascaderData = res.data
        })
      }
    }
  }
</script>

<style scoped>
</style>